<template>
  <section>
    <div v-if="type=='error'">
      <b-notification
        v-if="hasError"
        :type="getType('error')"
        aria-close-label="Close notification"
        role="alert"
      >
        {{ error }}
      </b-notification>
    </div>
    <div v-else>
      <b-notification
        v-for="(notification,index) in notifications"
        :key="index"
        :type="getType(notification.type)"
        aria-close-label="Close notification"
        role="alert"
      >
        <span v-html="notification.text" />
      </b-notification>
    </div>
  </section>
</template>

<script>
import { useErrorStore } from '@/stores/error'

const STATE = {
  'error': 'is-danger',
  'warning': 'is-warning',
  'message': 'is-info',
  'notification': 'is-info'
}

export default {
  name: 'Error',
  props: {
    type: {
      type: String,
      default: 'error'
    }
  },
  setup () {
    const errorStore = useErrorStore()
    return { errorStore }
  },
  methods: {
    getType: function (item) {
      return STATE[item]
    }
  },
  computed: {
    error: function () {
      return this.errorStore.error
    },
    hasError: function () {
      return this.errorStore.hasError
    },
    notifications: function () {
      return this.errorStore.notifications
    },
    hasNotifications: function () {
      return this.errorStore.hasNotifications
    }
  }
}
</script>

<style>
  .notification.is-info {
    background-color: #1896E1;
    color: #fff;
  }
  .notification {
    margin-bottom: 1.5em;
  }
</style>
