/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
/* Router & Store */
import router from './router'
import NProgress from 'vue-nprogress'
/* Vue. Main component */
import App from './App.vue'
import pinia from '@/stores/initPinia.js'
import '@/plugins/buefy.js'
import { scientific } from '@/assets/js/scientific'
import { useWorkspaceStore } from '@/stores/workspacestore'

Vue.filter('scientific', scientific)

Vue.use(NProgress)

const nprogress = new NProgress()

new Vue({
  router,
  nprogress,
  created () {
    window.addEventListener('beforeunload', this.closeWorkspace)
  },
  methods: {
    closeWorkspace () {
      const workspaceStore = useWorkspaceStore()
      return workspaceStore.closeWorkspace()
    }
  },
  pinia,
  render: h => h(App)
}).$mount('#app')
