var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero mb-20",attrs:{"id":"wholeSection"}},[_c('div',{staticClass:"hero-head is-primary linear-gradient"},[_c('nav',{staticClass:"navbar is-transparent is-fixed-top linear-gradient"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/img/Logo_asterics_bl.svg"),"alt":"Logo"}})]),_c('a',{staticClass:"navbar-burger",class:{ 'is-active': _vm.activator },attrs:{"role":"button","aria-label":"menu","aria-expanded":"false","data-target":"navbarBasicExample"},on:{"click":_vm.makeBurger}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])],1),(_vm.$route.name === 'help')?_c('div',{staticClass:"help-header has-text-centered"},[_c('span',{staticClass:"navbar-item",staticStyle:{"left":"-100px","top":"18px","font-size":"16px","letter-spacing":"0.03em","font-weight":"bold","color":"white","display":"unset"}},[_vm._v("Help")]),_c('div',{staticClass:"navbar-item",staticStyle:{"position":"absolute","right":"0","top":"8px"}},[_c('b-button',{attrs:{"rounded":"","outlined":"","inverted":"","type":"is-primary"}},[_c('a',{staticClass:"doc",attrs:{"target":"_blank","href":"https://asterics.pages.mia.inra.fr/user_documentation/"}},[_c('span',[_vm._v("Documentation")])])]),_c('a',{staticClass:"doc",staticStyle:{"color":"white","font-weight":"bold","padding-left":"20px"},attrs:{"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.close()}}},[_c('span',[_vm._v("X")])])],1)]):_c('div',{staticClass:"navbar-menu",class:{ 'is-active': _vm.activator },attrs:{"id":"navbarBasicExample"}},[_c('div',{staticClass:"navbar-end",on:{"click":_vm.makeBurger}},[_c('router-link',{staticClass:"navbar-item keepvisible",class:{
                'is-disabled': !_vm.hasUuid
              },attrs:{"to":"/workspace"}},[_c('div',{staticClass:"menu-item"},[_c('img',{attrs:{"src":require('@/assets/img/header/workspace_bl.svg')}}),_c('span',[_vm._v("My workspace")])])]),_c('b-navbar-dropdown',{attrs:{"hoverable":_vm.hasUuid && _vm.hasDataset,"arrowless":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('router-link',{staticClass:"navbar-item",class:{
                  'is-disabled': !_vm.hasUuid || !_vm.hasDataset
                },attrs:{"to":"/edit"},on:{"click":function($event){return _vm.dropdown(false)}}},[_c('div',{staticClass:"menu-item"},[_c('img',{attrs:{"src":require('@/assets/img/header/edit_bl.svg')}}),_c('span',[_vm._v("Edit")])])])]},proxy:true}])},_vm._l((_vm.analyses['edit']),function(value,key){return _c('b-navbar-item',{key:key,attrs:{"to":value.link,"tag":"router-link"}},[_c('img',{attrs:{"src":_vm.getImgPath(value.submenuIcon)}}),_vm._v(" "+_vm._s(value.title)+" ")])}),1),_c('b-navbar-dropdown',{attrs:{"hoverable":_vm.hasUuid && _vm.hasDataset,"arrowless":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('router-link',{staticClass:"navbar-item",class:{
                'is-disabled': !_vm.hasUuid || !_vm.hasDataset
              },attrs:{"to":"/explore"}},[_c('div',{staticClass:"menu-item"},[_c('img',{attrs:{"src":require('@/assets/img/header/explore_bl.svg')}}),_c('span',[_vm._v("Explore")])])])]},proxy:true}])},_vm._l((_vm.analyses['explore']),function(value,key){return _c('b-navbar-item',{key:key,attrs:{"to":value.link,"tag":"router-link"}},[_c('img',{attrs:{"src":_vm.getImgPath(value.submenuIcon)}}),_vm._v(" "+_vm._s(value.title)+" ")])}),1),_c('b-navbar-dropdown',{attrs:{"hoverable":_vm.hasUuid && _vm.hasDataset,"arrowless":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('router-link',{staticClass:"navbar-item",class:{
                  'is-disabled': !_vm.hasUuid || !_vm.has2Dataset
                },attrs:{"to":"/integrate"}},[_c('div',{staticClass:"menu-item"},[_c('img',{attrs:{"src":require('@/assets/img/header/integrate_bl.svg')}}),_c('span',[_vm._v("Integrate")])])])]},proxy:true}])},_vm._l((_vm.analyses['integrate']),function(value,key){return _c('b-navbar-item',{key:key,attrs:{"to":value.link,"tag":"router-link"}},[_c('img',{attrs:{"src":_vm.getImgPath(value.submenuIcon)}}),_vm._v(" "+_vm._s(value.title)+" ")])}),1),_c('div',{staticClass:"navbar-item"},[_c('b-button',{attrs:{"rounded":"","outlined":"","inverted":"","type":"is-primary"}},[_c('a',{staticClass:"doc",attrs:{"target":"_blank","href":"https://asterics.pages.mia.inra.fr/user_documentation/"}},[_c('span',[_vm._v("Documentation")])])])],1)],1)])])])]),(_vm.currentAnalysis)?_c('div',{staticClass:"hero-head analysis-menu"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-page"},[_c('div',[_vm._v(" "+_vm._s(_vm.currentAnalysis.title)+" ")])]),_c('div',{staticClass:"b-tabs"},[_c('nav',{staticClass:"tabs is-centered"},[_c('ul',_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:index,class:{
                    'is-active': _vm.selectedItem===item.name,
                    'is-disabled': item.disabled,
                    'analysis-menu-item': true
                  },on:{"click":function($event){return _vm.clickItem(item)}}},[_c('a',{},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)])])])]):_c('div',{staticClass:"analysis-menu-empty"})])
}
var staticRenderFns = []

export { render, staticRenderFns }