import axios from 'axios'
import Cookies from 'js-cookie'
import errorsDictionary from '../../data/errorsDictionary.json'
import url from './urlEnvironment.js'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useErrorStore } from '@/stores/error'

const http = axios.create({
  baseURL: url.API_URL,
  timeout: 60 * 4 * 1000,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': Cookies.get('csrftoken')
  }
})

http.interceptors.request.use(
  function (config) {
    const workspaceStore = useWorkspaceStore()
    const uuid = workspaceStore.uuid
    if (!('uuid' in config.data) && uuid !== '') {
      config.data['uuid'] = uuid
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  function (response) {
    const errorStore = useErrorStore()
    if (response.data.Messages) {
      errorStore.setNotifications(response.data.Messages.data)
    }
    return response
  },
  function (error) {
    const errorStore = useErrorStore()
    return new Promise(function () {
      if (error.response.data.error.startsWith('400 Bad Request:')) {
        error.response.data.error = error.response.data.error.substring(16)
        let errorDisplay = errorsDictionary.filter(obj => {
          if (obj.error.trim() === error.response.data.error.trim()) {
            return obj
          }
        })
        if (errorDisplay.length === 1) {
          error.response.data.error = errorDisplay[0].display
        }
        errorStore.setError(error.response.data.error)
      } else if (error.response.data.error.startsWith('503 Service Unavailable:')) {
        error.response.data.error = error.response.data.error.substring(24)
        errorStore.setError(error.response.data.error)
      } else {
        errorStore.setError(error.response.data.error)
      }
      throw error
    })
  }
)
export default http
