import { defineStore } from 'pinia'

export const useErrorStore = defineStore('errorStore', {
  state: () => {
    return {
      error: '',
      notifications: []
    }
  },
  actions: {
    reset () {
      this.$reset()
    },
    setError (error) {
      this.error = error
    },
    setNotifications (items) {
      this.notifications = items
    },
    setNotification (item) {
      this.notifications = [item]
    }

  },
  getters: {
    hasError: state => state.error !== '',
    hasNotificationError: state => state.notifications.filter(obj => obj.type === 'error').length > 0, // has notification type error
    hasNotifications: state => state.notifications.length > 0 & state.status === 'notification'
  }
})
