<template>
    <div>
      <div class="colored-footer" style="height: 85px;">
        <div class="container">
          <div class="columns is-mobile is-vcentered" style="height: 85px;">
            <div class="column is-one-third" style="margin-left: 20px;"><img src="@/assets/img/footer/logo-footer.svg" alt="Logo"></div>
            <div class="column is-one-third has-text-centered">
              <b-button rounded
                :href="metadata.doc_url"
                tag="a"
                size="is-small"
                class="button-shadow"
                label="Documentation"
                type="is-primary"
                target="_blank"
                style="margin-right: 10px"/>
              <b-button rounded
                class="button-shadow"
                label="Contact us"
                type="is-primary"
                @click="mailto()"
                size="is-small"
                style="margin-right: 10px"/>
              <b-button rounded
                :href="metadata.report_bug_url"
                tag="a"
                size="is-small"
                class="button-shadow"
                label="Report a bug"
                type="is-primary"
                target="_blank"
               />
            </div>
            <div class="column is-two-fifths"></div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="container">
          <div class="columns is-desktop">
            <div class="column is-two-fifths">
              <span>Asterics {{metadata.source_version}} &copy; {{ metadata.copyright_year }}</span>
              &mdash; <router-link class="link" to="/termofuse">
                Terms of service
              </router-link>
              &mdash; <router-link class="link" to="/legalnotice">
                Legal notice
              </router-link>
            </div>
            <div class="column is-one-fifth has-text-centered">
              <a class="link" href="https://forgemia.inra.fr/asterics/asterics" target="_blank">
                <img src="@/assets/img/footer/gitlab.svg"/> Source code
              </a>
            </div>
            <div class="column is-two-fifths right">
            <div class="logos">
              <div class="logo">
                <a target="_blank" href="http://bioinfo.genotoul.fr/">
                  <img src="@/assets/img/logo-genotoul-Bioinfo.svg" />
                </a>
              </div>
              <div class="logo">
                <a target="_blank" href="https://www.genotoul.fr/portfolio_page/biostatistiques/">
                  <img src="@/assets/img/logo-genotoul-Biostat.svg" />
                </a>
              </div>
              <div class="logo">
                <a target="_blank" href="https://www.laregion.fr/">
                  <img src="@/assets/img/logo_RegionOccitanie.jpg" />
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
      </footer>
  </div>
</template>

<script>
export default {
  name: 'FooterBar',
  props: {
    metadata: {
      type: Object,
      default: null
    }
  },
  methods: {
    mailto: function () {
      document.location.href = 'mailto:' + this.metadata.contact
    }
  }

}
</script>
<style scoped>
.colored-footer {
  background-color: #2664EC;
  background-image: url("../assets/img/footer/deco-footer.svg");
  background-repeat: no-repeat;
  background-position:  90% 10%;
  margin-bottom: 0 !important;
}
.link{
  color: #363636;
}
.logos{
  display: flex;
  justify-content: right;

}
</style>
