function getDigits (value) {
  const number = Number(value)
  const string = number.toString()
  const hasExponent = string.indexOf('e') !== -1 // is the number written using the scientific notation
  if (hasExponent) {
    const exponent = string.split('e')[1].substring(1)
    return parseInt(exponent) + 1
  }
  return number.toString().length
}

export function scientific (value, decimals) {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  decimals = decimals != null ? decimals : 6
  var nblength = getDigits(value)
  if (nblength > decimals + 2) {
    return value.toExponential()
  } else {
    return value
  }
}
