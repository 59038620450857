<template>
  <section class="hero mb-20" id="wholeSection" >
    <!-- Hero head: will stick at the top -->
    <div class="hero-head is-primary  linear-gradient ">
      <nav class="navbar is-transparent is-fixed-top linear-gradient ">
        <div class="container">
        <div class="navbar-brand">
          <router-link class="navbar-item" to="/">
            <img src="../assets/img/Logo_asterics_bl.svg" alt="Logo">
          </router-link>
          <a role="button" @click="makeBurger" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"  v-bind:class="{ 'is-active': activator }">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="help-header  has-text-centered" v-if="$route.name === 'help'">
            <span class="navbar-item" style="left: -100px; top:18px; font-size: 16px; letter-spacing: 0.03em;font-weight: bold;color: white;display: unset;">Help</span>
            <div class="navbar-item" style="position: absolute; right: 0; top:8px" >
                  <b-button rounded outlined inverted
                    type="is-primary"
                  > <a class="doc"
                    target="_blank"
                    href="https://asterics.pages.mia.inra.fr/user_documentation/">
                    <span>Documentation</span>
                    </a>
                </b-button>
            <a class="doc"
                    target="_blank"
                    @click.prevent="close()"
                    style="color:white; font-weight:bold;padding-left:20px;">
                    <span>X</span>
              </a>
            </div>
        </div>
        <div id="navbarBasicExample"
          class="navbar-menu"
          v-bind:class="{ 'is-active': activator }"
          v-else >
           <div class="navbar-end" @click="makeBurger">
              <router-link
                :class="{
                  'is-disabled': !hasUuid
                }"
                class="navbar-item keepvisible"
                to="/workspace"
              >
                <div class="menu-item">
                <img
                  :src="require('@/assets/img/header/workspace_bl.svg')"
                />
                <span>My workspace</span>
                </div>
              </router-link>
              <b-navbar-dropdown :hoverable="hasUuid && hasDataset" arrowless>
               <template v-slot:label>
                <router-link
                  :class="{
                    'is-disabled': !hasUuid || !hasDataset
                  }"
                  class="navbar-item"
                  to="/edit"
                  @click="dropdown(false)"
                > <div class="menu-item">
                  <img
                    :src="require('@/assets/img/header/edit_bl.svg')"
                  />
                  <span>Edit</span>
                  </div>
                </router-link>
               </template>
                  <b-navbar-item v-for="(value, key) in analyses['edit']"
                  :to="value.link"
                  :key="key"
                  tag="router-link">

                  <img
                  :src="getImgPath(value.submenuIcon)"
                  /> {{value.title}}

                </b-navbar-item>
              </b-navbar-dropdown>
              <b-navbar-dropdown :hoverable="hasUuid && hasDataset" arrowless>
               <template v-slot:label>
                <router-link
                :class="{
                  'is-disabled': !hasUuid || !hasDataset
                }"
                class="navbar-item"
                to="/explore"
                >
                  <div class="menu-item">
                    <img
                      :src="require('@/assets/img/header/explore_bl.svg')"
                    />
                    <span>Explore</span>
                  </div>
                </router-link>
               </template>
                <b-navbar-item v-for="(value, key) in analyses['explore']"
                  :to="value.link"
                  :key="key"
                  tag="router-link">
                  <img
                  :src="getImgPath(value.submenuIcon)"
                  /> {{value.title}}
                </b-navbar-item>
              </b-navbar-dropdown>
              <b-navbar-dropdown :hoverable="hasUuid && hasDataset" arrowless>
               <template v-slot:label>
                <router-link
                  :class="{
                    'is-disabled': !hasUuid || !has2Dataset
                  }"
                  class="navbar-item"
                  to="/integrate"
                >
                  <div class="menu-item">
                    <img
                      :src="require('@/assets/img/header/integrate_bl.svg')"
                    />
                    <span>Integrate</span>
                  </div>
                </router-link>
               </template>
               <b-navbar-item v-for="(value, key) in analyses['integrate']"
                  :to="value.link"
                  :key="key"
                  tag="router-link">
                  <img
                  :src="getImgPath(value.submenuIcon)"
                  /> {{value.title}}
                </b-navbar-item>
              </b-navbar-dropdown>
              <div class="navbar-item">
                <b-button rounded outlined inverted
                  type="is-primary"
                > <a class="doc"
                  target="_blank"
                  href="https://asterics.pages.mia.inra.fr/user_documentation/">
                  <span>Documentation</span>
                  </a>
              </b-button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-head analysis-menu" v-if="currentAnalysis">
      <div class="container" >
        <div class="title-page">
          <div >
              {{ currentAnalysis.title }}
          </div>
        </div>
        <div class="b-tabs">
            <nav class="tabs is-centered">
                <ul>
                  <li
                    v-for="(item,index) in menuItems"
                    :key="index"
                    :class="{
                      'is-active': selectedItem===item.name,
                      'is-disabled': item.disabled,
                      'analysis-menu-item': true
                    }"
                    @click="clickItem(item)"
                  >
                    <a class=""> {{ item.title }} </a>
                  </li>
                </ul>
            </nav>

        </div>
      </div>
    </div>
    <div class="analysis-menu-empty" v-else>
    </div>
  </section>
</template>

<script>

import allAnalyses from '../../data/analysis.json'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useErrorStore } from '@/stores/error'
import { useMenuStore } from '@/stores/menu'
export default {
  name: 'MainNavbar',
  data: function () {
    return {
      activeItem: 0,
      activator: false,
      analyses: allAnalyses
    }
  },

  setup () {
    const menuStore = useMenuStore()
    const workspaceStore = useWorkspaceStore()
    const errorStore = useErrorStore()
    return { workspaceStore, errorStore, menuStore }
  },
  methods: {
    makeBurger () {
      this.activator = !this.activator
      return this.activator
    },
    clickItem: function (item) {
      this.errorStore.reset()
      this.menuStore.selectItem(item)
    },
    getImgPath: function (imgfile) {
      return (require('@/assets/img/header/submenu/' + imgfile))
    },
    close: function () {
      close()
    }
  },
  computed: {
    currentAnalysis: function () {
      return this.menuStore.currentAnalysis
    },
    menuItems: function () {
      return this.menuStore.menuItems
    },
    selectedItem: function () {
      return this.menuStore.selectedItem
    },
    hasUuid: function () {
      return this.workspaceStore.hasUuid
    },
    hasDataset: function () {
      return this.workspaceStore.hasDataset
    },
    has2Dataset: function () {
      return this.workspaceStore.has2Dataset
    }
  }
}
</script>

<style>

  .linear-gradient {

    background: linear-gradient(to right, #564af9, #1db4ce) !important;
  }
  .linear-gradient > nav {
    box-shadow: 0px 3px 5px 3px rgb(20 20 21 / 0.10);
  }
  .navbar-item {
    border-width: 1px;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #1896E1 !important;
  }
  .navbar-item:hover {
    color: none !important;
  }
  .menu-item {
    display: flex;
    color: #FFFFFF;
    align-items:center;
  }
  .router-link-active > .menu-item > span {
    border-bottom: 2px solid currentColor;
  }
  .doc {
    font-size: 16px !important;
    color: currentColor;
    letter-spacing: 0.03em;
    text-decoration: none;
  }
  .doc:hover {
    color: currentColor;
  }
  .navbar-item.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .65;
  }

  .navbar-item img {
    max-height: 4rem !important;
    padding-right: 15px;
  }
  .navbar-brand {
    margin-left: 15px;
    padding-left: 15px;
  }
  .navbar-dropdown > a {
    letter-spacing: 0.03em;
    color: #1896E1 !important;
    margin-right: 40px;
    margin-bottom: 20px;
  }
  #navbarBasicExample {
    padding-right: 20px;
  }
  .analysis-menu {
    display: flex;
    margin-top: 70px;
    padding-top: 20px;
    background-color: white;
    border-bottom:  1px solid rgba(24, 28, 33, 0.06);
  }
  .analysis-menu-empty{
    margin-top: 80px;
    padding-bottom: 10px;
  }
  .title-page {
    margin-left: 15px;
    padding-left: 15px;
    font-weight: 600;
    float: left;
    font-size: 18px !important;
    padding-top: 4px;
    padding-bottom: 5px;
  }
  .tabs li.is-active.analysis-menu-item {
    font-weight: bold;
    border-bottom:  1px solid rgba(24, 28, 33, 0.06);
  }

  .help-header{
    width: 100%;
    display: relative;
    }

@media screen and (max-width: 1023px) {
.keepvisible {
  background-color: #1896E1;
  margin-bottom: 15px;
  padding-left: 30px;
}
}

@media screen and (max-width: 1023px) {
nav.navbar .navbar-menu .navbar-item.has-dropdown > .navbar-link {
  background-color: #1896E1;
}
}

@media screen and (max-width: 1023px) {
a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  background-color: hsl(0, 0%, 85%);
  color: hsl(229, 53%, 53%);
}
}

</style>
