// import Vue from 'vue'
// import { ConfigProgrammatic, Table, Input, Menu, Dropdown, Icon, Upload, Checkbox, Radio } from 'buefy'
// import 'buefy/dist/buefy.css'

// Vue.use(Input)
// Vue.use(Table)
// Vue.use(Menu)
// Vue.use(Dropdown)
// Vue.use(Icon)
// Vue.use(Upload)
// Vue.use(Checkbox)
// Vue.use(Radio)

// ConfigProgrammatic.setOptions({
//     defaultIconPack: 'fa'
// })

import Vue from 'vue'
import Buefy from 'buefy'
import '../css/style.css'

Vue.use(Buefy, {
  defaultIconPack: 'fa',
  customIconPacks: {
    'icomoon': {
      sizes: {
        'default': 'is-size-5',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'icon-',
      internalIcons: {
        'xaxis': 'xaxis',
        'yaxis': 'yaxis',
        'color': 'color',
        'size': 'size',
        'shape': 'shape',
        'arrow-down': 'arrow-down',
        'correlation': 'correlation',
        'basic-test': 'basic-test',
        'cross-table': 'cross-table'
      }
    }
  }
})
