import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menuStore', {
  state: () => {
    return {
      menuItems: [], // tabs in an analysis [eg univariate, bivariate ...]
      selectedItem: null,
      currentAnalysis: null
    }
  },
  actions: {
    reset () {
      this.$reset()
    },
    selectItem (item) {
      this.selectedItem = item.name
    },
    selectItemName (name) {
      this.selectedItem = name
    },
    setAnalysis (analysis) {
      this.currentAnalysis = analysis
    },
    setMenuItems (items) {
      this.menuItems = items
      this.selectedItem = items[0].name
    }
  }
})
