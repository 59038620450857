<template>
  <div id="app">
    <main-navbar v-if="$route.name !== 'home'"/>
    <div v-if="$route.name === 'home'">
      <home></home>
    </div>
    <div class="wrapper" v-else>
      <section class="hero is-default is-bold">
        <div  :class="{ 'help-body': isHelp, 'hero-body': !isHelp }">
          <div class="container">
            <error :type='"error"' />
            <router-view />
          </div>
        </div>
      </section>
    </div>
    <footer-bar :metadata='metadata'/>
  </div>
</template>

<script>
import MainNavbar from '@/layout/MainNavbar'
import FooterBar from '@/layout/FooterBar'
import Error from '@/components/ui/Error'
import apiService from '@/services/apiService'

export default {
  components: {
    Error,
    MainNavbar,
    FooterBar,
    'home': () => import('@/views/Home.vue')
  },
  data: function () {
    return {
      metadata: { 'contact': 'CONTACT_EMAIL',
        'report_bug_url': 'REPORT_BUG_URL',
        'source_version': 'SOURCE_VERSION',
        'copyright_year': 'COPYRIGHT_YEAR',
        'doc_url': 'DOC_URL' }
    }
  },
  computed: {
    isHelp () {
      return this.$route.name === 'help'
    }
  },
  mounted () {
    apiService.getMetadata().then(data => {
      this.metadata = data
    })
  }
}
</script>

<style>
  .hero-body {
    min-height:600px !important ;
    padding-top: 20px
  }
  #nprogress .bar {
    background: #ffffff !important;
  }
  #nprogress .peg {
    box-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff;
  }
  #nprogress .spinner-icon {
    border-top-color: #ffffff;
    border-left-color: #ffffff;
  }
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1380px;
  }
  .help-body {
    margin-top: -24px;
    margin-bottom: 24px;
  }
</style>
