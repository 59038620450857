import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import '../../node_modules/nprogress/nprogress.css'
import pinia from '@/stores/initPinia.js'
import { useErrorStore } from '@/stores/error'
import { useMenuStore } from '@/stores/menu'

import allAnalyses from '../../data/analysis.json'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  hashbang: false,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/Admin.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/PrivacyPolicy.vue')
    },
    {
      path: '/workspace',
      name: 'workspace',
      component: () => import('@/views/Workspace.vue')
    },
    {
      path: '/termofuse',
      name: 'termofuse',
      component: () => import('@/views/TermOfUse.vue')
    },
    {
      path: '/legalnotice',
      name: 'legalnotice',
      component: () => import('@/views/LegalNotice.vue')
    },
    {
      path: '/workspace/:uuid',
      name: 'workspace-uuid',
      component: () => import('@/views/Workspace.vue')
    },
    {
      path: '/all-datasets',
      name: 'all-datasets',
      component: () => import('@/views/AllDatasets.vue')
    },
    {
      path: '/import-dataset',
      name: 'import-dataset',
      component: () => import('@/views/ImportDataset.vue')
    },
    {
      path: '/edit',
      name: 'editwf',
      component: () => import('@/views/Edit.vue')
    },
    {
      path: '/explore',
      name: 'explore',
      component: () => import('@/views/Explore.vue')
    },
    {
      path: '/integrate',
      name: 'integrate',
      component: () => import('@/views/Integrate.vue')
    },
    {
      path: '/help/:wfclass',
      name: 'help',
      component: () => import('@/views/Help.vue'),
      props: true
    },
    {
      path: '/workflow/:name',
      name: 'workflow',
      component: () => import('@/views/RWorkflow.vue')
    },
    {
      path: '/edit/missing',
      name: 'missing',
      component: () => import('@/components/rworkflows/RMissing.vue')
    },
    {
      path: '/edit/normalization',
      name: 'normalization',
      component: () => import('@/components/rworkflows/RNormalization.vue')
    },
    {
      path: '/edit/edit',
      name: 'edit',
      component: () => import('@/components/rworkflows/REdit.vue')
    },
    {
      path: '/explore/variate',
      name: 'variate',
      component: () => import('@/components/rworkflows/RVariate.vue')
    },
    {
      path: '/explore/pca',
      name: 'pca',
      component: () => import('@/components/rworkflows/RPCA.vue')
    },
    {
      path: '/explore/heatmap',
      name: 'heatmap',
      component: () => import('@/components/rworkflows/RHeatmap.vue')
    },
    {
      path: '/integrate/pls',
      name: 'pls',
      component: () => import('@/components/rworkflows/RPLS.vue')
    },
    {
      path: '/integrate/plsda',
      name: 'plsda',
      component: () => import('@/components/rworkflows/RPLSDA.vue')
    },
    {
      path: '/integrate/mfa',
      name: 'mfa',
      component: () => import('@/components/rworkflows/RMFA.vue')
    },
    {
      path: '/integrate/differentialanalysis',
      name: 'differentialanalysis',
      component: () => import('@/components/rworkflows/RDiff.vue')
    },
    {
      path: '/explore/clustering',
      name: 'clustering',
      component: () => import('@/components/rworkflows/RClustering.vue')
    },
    {
      path: '/explore/som',
      name: 'som',
      component: () => import('@/components/rworkflows/RSOM.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/Contact.vue')
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/views/404.vue')
    }
  ]
})

router.beforeResolve((to, from, next) => {
  const menuStore = useMenuStore(pinia)
  const errorStore = useErrorStore(pinia)
  menuStore.reset()
  errorStore.reset()

  if (to.name && to.name !== 'home') {
    router.app.$nprogress.start()
  }
  next()
})

router.afterEach((to, from) => {
  setTimeout(() => router.app.$nprogress.done(), 500)
  // define the current analysis object with title and co...
  let routeSplited = to.path.split('/')
  let section = routeSplited[1]
  let currentAnalyse = null
  if (section !== '') {
    if (section in allAnalyses) {
      currentAnalyse = allAnalyses[section].filter(obj => obj.link === to.path)[0]
    }
  }
  const menuStore = useMenuStore(pinia)
  menuStore.setAnalysis(currentAnalyse)
})

export default router
