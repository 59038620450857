
<template>
<div>
<section class="hero mb-40 is-large">
    <div class="hero-head home-bleu"  >
      <img src="../assets/img/home/illus.png">
    </div>
</section>
<div class="columns">
      <div class="column"></div>
      <div class="column" style="margin-top:-15%">
        <card-component class="home button-shadow" >
          <b-tabs @input="displayForm" type="is-toggle-rounded" position="is-centered">
            <b-tab-item label="Create new workspace" icon="plus" value="new-workspace" />
            <b-tab-item label="Load workspace" icon="upload" value="load-workspace" />
          </b-tabs>
          <div v-if="currentForm==='new-workspace'">
          <validation-observer v-slot="{ handleSubmit }">
            <validation-provider
              v-slot="{ errors }"
              :rules= {required:true,email:true}
              name="email"
            >
              <b-field
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-input
                  icon="envelope"
                  placeholder="Email"
                  v-model="email"
                  expanded
                ></b-input>
              </b-field>
            </validation-provider>
              <validation-provider
              v-slot="{ errors }"
              :rules= {does_not_contains:true}
              :customMessages = "{does_not_contains: 'The label should not contain special symbols (except underscore) nor start with numbers.',
                   not_too_long: 'This label is too long'}"
              name="label"
            >
              <b-field
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-input
                  icon="ticket"
                  placeholder="Workspace name"
                  v-model="label"
                  expanded
                ></b-input>
              </b-field>
            </validation-provider>
            <b-field class="litle-check" style="padding-top:15px;">
              <b-checkbox v-model="loadDemo">Load TCGA demo data into your workspace
              </b-checkbox>
            </b-field>
            <b-field class="litle-check">
              <b-checkbox v-model="privacy">
                I confirm that I have read and that I agree to <em>Asterics'</em>
                <router-link class="link" target="_blank" to="/privacy-policy">
                  Privacy Policy.
                </router-link>
              </b-checkbox>
            </b-field>
            <div class="has-text-centered">
              <b-button rounded style="margin-top:15px;margin-bottom:15px;"
                class="is-primary button-shadow"
                :disabled="!privacy"
                @click="handleSubmit(createWorkspace)"
              >
                <span class="icon is-small"><i class="fa fa-plus"></i></span>
                <span> Create new workspace </span>
              </b-button>
              <div class="new-footer" style="padding-top:10px;">
                <br/><img src="../assets/icons/icone-remaining-days2.svg" style="padding-right:10px" /> Your unique identifier will allow you to access
                your work and data during 30 days after the last activity on ASTERICS.
              </div>
            </div>
          </validation-observer>
        </div>
        <div v-if="currentForm==='load-workspace'">
          <validation-observer v-slot="{ handleSubmit }">
            <validation-provider
              v-slot="{ errors }"
              :rules= {required:true}
              name="uuid"
            >
              <b-field
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                label="Workspace UUID"
              >
                <b-input
                  icon="tag"
                  placeholder="Workspace UUID"
                  v-model="iuuid"
                  @input="errorUuid=''"
                  expanded
                ></b-input>
              </b-field>
              <p class="help is-danger">{{errorUuid}}</p>
            </validation-provider>
            <div class="has-text-centered" style="margin-top:15px;margin-bottom:15px;">
              <b-button rounded
                class="is-primary button-shadow"
                @click="handleSubmit(loadWorkspace)"
              >
                <span class="icon is-small"><i class="fa fa-upload"></i></span>
                <span> Load workspace </span>
              </b-button>
            </div>
          </validation-observer>
        </div>
        </card-component>
      </div>
      <div class="column"></div>
    </div>
    <div class="container">
    <div style="padding-right:40px;padding-left:40px">
      <vueper-slides
        class="no-shadow"
        :visible-slides="5"
        slide-multiple
        :autoplay="true"
        :gap="1"
        :dragging-distance="200"
        fixed-height="200px"
        >
        <vueper-slide v-for="(slide, i) in partner" :key="i" :content="slide.content" />
      </vueper-slides>
      </div>
      </div>
</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { v4 as uuidv4 } from 'uuid'
import CardComponent from '@/components/ui/CardComponent'
import apiService from '@/services/apiService'

export default {
  name: 'Home',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    CardComponent,
    VueperSlides,
    VueperSlide
  },
  data () {
    return {
      uuid: uuidv4(),
      email: '',
      label: '',
      loadDemo: false,
      privacy: false,
      iuuid: null,
      currentForm: 'new-workspace',
      errorUuid: '',
      values: 0,
      partner: [{
        alt: 'Logo bioinfo',
        content: '<a href="http://bioinfo.genotoul.fr"><img src="' + require('@/assets/img/home/logo-bioinfo.png') + '"></a>'
      },
      {
        alt: 'Logo biostat',
        content: '<a href="https://www.genotoul.fr/portfolio_page/biostatistiques"><img src="' + require('@/assets/img/home/logo-biostat.png') + '"></a>'
      },
      {
        alt: 'Logo region',
        content: '<a href="https://www.laregion.fr/"><img src="' + require('@/assets/img/home/logo-region.png') + '"></a>'
      },
      { alt: 'Logo MIAT',
        content: '<a href="https://miat.inrae.fr"><img src="' + require('@/assets/img/home/logo-mia.png') + '"></a>'
      },
      { alt: 'Logo Inrae',
        content: '<a href="https://inrae.fr"><img src="' + require('@/assets/img/home/logo-inrae.png') + '"></a>'
      },
      {
        alt: 'Logo GenPhyse',
        content: '<a href="https://genphyse.toulouse.inra.fr/"><img src="' + require('@/assets/img/home/logo-genphyse.png') + '"></a>'
      },
      {
        alt: 'Logo IMT',
        content: '<a href="https://www.math.univ-toulouse.fr"><img src="' + require('@/assets/img/home/logo-institut-math.png') + '"></a>'
      },
      {
        alt: 'Logo Hyphen',
        content: '<a href="https://www.hyphen-stat.com/en/"><img src="' + require('@/assets/img/home/logo-hyphen.png') + '"></a>'
      }]
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    url: function () {
      let url = location.protocol + '//' + location.hostname
      if (location.port) {
        url += ':' + location.port
      }
      return url + '/workspace/'
    },
    workspaceUid: function () {
      if (this.label) {
        return (this.label + this.uuid)
      } else {
        return (this.uuid)
      }
    }
  },
  mounted () {
    extend('does_not_contains', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.match(/^[a-zA-Z0-9_]*$/) && !value.match(/^\d/)
    })
    extend('not_too_long', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.length < 40
    })
  },
  methods: {
    displayForm: function (value) {
      this.errorUuid = ''
      this.currentForm = value
    },
    loadWorkspace: function () {
      apiService
        .UUIDExists({ uuid: this.iuuid })
        .then((data) => {
          if (data.exists) {
            this.$router.push({ name: 'workspace-uuid', params: { uuid: this.iuuid } })
          } else {
            // display error
            this.errorUuid = 'This workspace does not exist.'
          }
        })
        .catch(() => {
          // display error
          this.errorUuid = 'This workspace does not exist.'
        })
    },
    createWorkspace: function () {
      if (this.loadDemo) {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.workspaceStore.createWorkspace({
          'uuid': this.workspaceUid,
          'email': this.email,
          'url': this.url
        }).then(() => {
          this.workspaceStore.addDemoDataset()
            .then(() => {
              loadingComponent.close()
              this.$router.push({ name: 'workspace-uuid', params: { uuid: this.workspaceUid } })
            })
            .catch(() => {
              loadingComponent.close()
            })
        }).catch(() => {
          loadingComponent.close()
        })
      } else {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.workspaceStore.createWorkspace({
          'uuid': this.workspaceUid,
          'email': this.email,
          'url': this.url
        }).then(() => {
          loadingComponent.close()
          this.$router.push({ name: 'workspace-uuid', params: { uuid: this.workspaceUid } })
        }).catch(() => {
          loadingComponent.close()
        })
      }
    }
  }
}
</script>
<style>
.home-bleu{
  padding-top: 60px;
  background: linear-gradient(#6045FB, #274EFB);
  text-align: center;
}

 .home .tab-content {
    padding: 0px;
    height: 0px;
  }
  .home li.is-active a {
    background-color: #14bcef;
    color: white;
  }
  .home card-component {
    width:550px;
    padding: 35px;
  }
  .home .tabs li.is-active a {
    background-color: #14bcef !important;
    border-color: #14bcef !important;
  }
  .home .hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current) {
    color: #1896E1 !important;
  }
  .litle-check {
    display: flex;
    font-size: 87%;
  }
  .new-footer {
    display: flex;
    font-size: 77%;
  }
  .carousel-slide {
    padding-right:5px;
    padding-right:5px;
  }
</style>
